import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo } from './icons'
import parse from 'html-react-parser'

class Footer extends Component {
  render() {

    let { menus, email, phone, address } = this.props.data.wp.siteSettings.acf

    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__left'>
            <Link to='/' title='BD Realty' className='footer__logo footer__logo--desktop'>
              <Logo color='#FFF1E2' />
            </Link>
            <div className='footer__contact'>
              <ul>
                { email?.url && <li><Link to={email.url}>{email.title}</Link></li> }
                { phone?.url && <li><Link to={phone.url}>{phone.title}</Link></li> }
              </ul>
              { address && <p>{parse(address)}</p> }
            </div>
          </div>
          <div className='footer__right'>
            <Link to='/' title='BD Realty' className='footer__logo footer__logo--mobile'>
              <Logo color='#FFF1E2' />
            </Link>
            { menus?.length > 0 && menus.map((el, i) => (
              <div className='footer__menu' key={i}>
                { el.title && <h4>{parse(el.title)}</h4> }
                { el.menu?.length > 0 &&
                  <ul>
                    { el.menu.map((menu, m) => (
                      <li key={m}>
                        <Link to={menu.link.url}>{menu.link.title}</Link>
                      </li>
                    ))}
                  </ul>
                }
              </div>
            ))}
          </div>
          <div className='footer__bottom'>
            <div className='footer__left'>
              <div className='footer__copy'>
                <p>© BD Realty 2025, All rights reserved.</p>
                <Link to='/privacy-policy/'>Privacy Policy</Link>
              </div>
            </div>
            <div className='footer__right'>
              <ul className='footer__terms'>
                <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
                <li><p>© BD Realty 2025, All rights reserved.</p></li>
                <li>Design by <Link to='https://atollon.com.au/?utm_source=bdrealty&utm_medium=website&utm_campaign=referrer&utm_term=realestate'>Atollon</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
